import React from 'react';

class NavbarComponent extends React.Component {
  render() {
    return (
      <div class="navbar">
        <div class="navbar-item">Home</div>
        <div class="navbar-item">Projects</div>
        <div class="navbar-item">Experience</div>
        <div class="navbar-item">Contact</div>
      </div>
    )
  }
}

export default NavbarComponent;